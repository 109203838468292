@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap');

/* #root {
    height: 100%;
} */

.contactIcons {
    display: flex;
    justify-content: space-evenly;
    /* flex-direction: row; */

    padding-top: 25px;
    padding-left: 0;
    font-family: 'Nunito',
    sans-serif;
    text-transform: uppercase;
    /* border: red solid 1px; */
    
}

/* .linkedIn {
    border: red solid 1px;
} */

/* .intro:hover, .intro:focus {
    background-color: var(--opaqe);
    border: red solid 1px;
} */

/* .work:hover, .work:focus {
    background-color: var(--opaqe);
} */

/* .contactIcons:hover, .about:focus {
    background-color: var(--opaqe);
} */

.dreGit {
    border: red solid 1px;
}

.twitter:hover {
    

    -webkit-transform: rotateZ(720deg);
      -moz-transform: rotateZ(720deg);
      transform: rotateZ(720deg);
      /* border: red solid 1px; */
   
}


/* .contactIcons {
    display: inline-block;
    width: 200px;
    border: 1px solid red;
    overflow: hidden;
    text-align: center;
} */

/* .linkedIn:hover{
    color: blue;

} */