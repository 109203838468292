@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
#root {
  height: 100%;
  width: 100%;
  /* border: solid 5px red */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media all and (max-width: 400px) {

  #root {
    /* display: flex;
    flex-direction: column; */
  height: 100%;
  width: 100%;
  /* border: solid 5px red */
}

body {
  /* display: flex;
    flex-direction: column; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
}
/* .awSpan { */
    /* filter: blur(8px); */
    /* display: flex;
    margin-top: 25%;
    justify-content: center;
    flex-grow: 4;
} */

.LandingPageCircle_circleImage__33JDg {
    height: 98px;
    width: 98px;
    border-radius: 50px;
}

.LandingPageCircle_circle__2j44O {
    /* filter: blur(8px); */
    display: flex;
    align-self: center;
    font-family: 'Nunito',
    sans-serif;
    text-transform: uppercase;
    height: 100px;
    width: 100px;
    border: solid var(--line) 1px;
    border-radius: 50%;
    /* background-color: green; */
}

.LandingPageContainer_lineUnderCircle__3wS7w {
    height: 100px;
    border-left: solid var(--line) 1px;
    margin-left: 50%;
}



.LandingPageContainer_innerParagraph__1-gmh {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--text-primary);
    border-top: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 48px 32px;
    text-transform: uppercase;
    font-family: 'Nunito',
    sans-serif;
}

p {
    font-size: 13px;
}

.LandingPageContainer_downlineBottom__1MNk0 {
    height: 100px;
    border-left: solid var(--line) /* green*/ 1px;
    margin-left: 50%;
}

.LandingPageContainer_paragraph__45Ltk {
    display: flex;
}
/* .intro { */
    /* border: solid var(--line) 1px;
    padding: 20px 25px 20px 25px;
    border-radius: 4px 0 0 4px;
    text-decoration: none;
    color: var(--text-primary); */
    /* background-color: rgba(220, 20, 60, 0); */
    /* cursor: pointer; */


    /* display: block; */
    /* min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem; */
    /* border-bottom: 0; */
/* } */

/* .intro:hover, .intro:focus {
    background-color: var(--opaqe);
  } */

.LandingPageModal_work__1ht8d {
    border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 39px 20px 38px;
    text-decoration: none;
    color: var(--text-primary);
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer;

    /* display: block; */
    min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    /* border-bottom: 0; */
}

.LandingPageModal_work__1ht8d:hover, .LandingPageModal_work__1ht8d:focus {
    background-color: var(--opaqe);
  }

/* .about { */
    /* border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 27px 20px 20px;
    text-decoration: none;
    color: var(--text-primary);
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer; */

    /* display: block; */
    /* min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem; */
    /* border-bottom: 0; */
/* } */

/* .about:hover, .about:focus {
    background-color: var(--opaqe); */
    /* background-color: blue; */
  /* } */

.LandingPageModal_contact__2OHTC {
    border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 25px 20px 25px;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    color: var(--text-primary);;
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer;
    /* background-color: blue; */
}

.LandingPageModal_contact__2OHTC:hover, .LandingPageModal_contact__2OHTC:focus {
    background-color: var(--opaqe);
    /* background-color: blue; */
  }

.LandingPageModal_modalBtnContainer__VXWd4 {
  padding-left: 45px;
  /* background-color: blue; */
    padding-left: 7.9%;
}

@media all and (max-width: 565px) {
    .LandingPageModal_modalBtnContainer__VXWd4 {
        contain: style layout inline-size;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 13%;
        left: 700px;
        padding-left: 7.9%;
        /* background-color: blueviolet; */
    }

        /* .intro{
            border: solid var(--line) 1px;
            border-radius: 4px 4px 4px 4px;
            width: 150px;

        } */

        .LandingPageModal_work__1ht8d {
            border: solid var(--line) 1px;
              border-radius: 4px 4px 4px 4px;
              width: 150px;
          }
          /* .about {
            border: solid var(--line) 1px;
              border-radius: 4px 4px 4px 4px;
              width: 150px;
          } */
          .LandingPageModal_contact__2OHTC {
            border: solid var(--line) 1px;
              border-radius: 4px 4px 4px 4px;
              width: 150px;
              /* background-color: tomato; */
          } 

}

.ModalIntro_intro__2R20p{
    padding: 25px;
    /* background-color: bisque; */
    border: solid 5px green;
}


.ModalIntro_intro__2R20p {
    border: solid var(--line) 1px;
    padding: 20px 25px 20px 25px;
    border-radius: 4px 0 0 4px;
    text-decoration: none;
    color: var(--text-primary);
    /* background-color: burlywood; */
    cursor: pointer;


    /* display: block; */
    min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    /* border-bottom: 0; */
}

.ModalIntro_intro__2R20p:hover,
.ModalIntro_intro__2R20p:focus {
    background-color: var(--opaqe);
}


@media all and (max-width: 565px) {
  
    .ModalIntro_intro__2R20p {
        border: solid var(--line) 1px;
        border-radius: 4px 4px 4px 4px;
        width: 150px;

    }
}
.Modal_listWork__1rU2V {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Nunito',
    sans-serif;
    font-weight: 200;
    font-size: 12px;  
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0,0, 0.7);
}

.Modal_modal__P3_V5 {
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 605px;
    width: 570px;
    border-radius: 25px;
    /* border: solid 5px green; */
}

.Modal_intro__2EWWz {
    display: flex;
    flex-direction: column;
    height: 630px;
    padding: 0;
    border-radius: 25px;
    text-decoration: none;
    color: white;
    background-color: rgba(220, 20, 60, 0);
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 3;
    transition: opacity 0.325s ease-in-out, -webkit-transform 0.325s ease-in-out;
    transition: opacity 0.325s ease-in-out, transform 0.325s ease-in-out;
    transition: opacity 0.325s ease-in-out, transform 0.325s ease-in-out, -webkit-transform 0.325s ease-in-out;
    /* border: solid 5px green; */
}

.Modal_modalHeader__2ik2T {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.Modal_modalTitle__BrJuw {
    display: inline-block;    
    margin-top: 100px;
    margin-left: 15px;
    border-bottom: 1px solid white;
}
button {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
}

.Modal_topClose__3QWm9 {
    display: block;
    position: absolute;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 500px;
    color: rgb(143, 237, 100);
    padding-bottom: 5px;
}

.Modal_topClose__3QWm9:hover {
    color: thistle;

}

.Modal_modalContent__1rYYD {
    padding-bottom: 50px;
}

@media all and (max-width: 600px) {
    .Modal_listWork__1rU2V {
        background: rgba(0, 0,0, 0.7);
        height: 100%;
    }

    .Modal_modal__P3_V5 {
        display: flex;
        justify-items: center;
        height: 100%;
        width: 100%;
    }

    .Modal_topClose__3QWm9{
        top: 0.875rem;
        left: 0.875rem;
        width: 2.25rem;
        height: 2.25rem;
        background-size: 14px 14px;
        margin-left: 80%;
        margin-top: -10px;
    }

    .Modal_modalTitle__BrJuw {
        margin-left: 45px;
        margin-top: 50px;
    }
}
.ModalWork_work__2I7PR {
   color: var(--text-primary); 
   background-color: blueviolet;
}

.ModalWork_modalWork__kztro {
    background-color: blueviolet;
}
.ModalAbout_about__ktVHN {
    border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 27px 20px 20px;
    text-decoration: none;
    color: var(--text-primary);
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer;

    /* display: block; */
    min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    /* border-bottom: 0; */
}

.ModalAbout_about__ktVHN:hover,
.ModalAbout_about__ktVHN:focus {
    background-color: var(--opaqe);
    /* background-color: blue; */
}

@media all and (max-width: 565px) {
    /* .modalBtnContainer {
        contain: style layout inline-size;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 13%;
        left: 700px;
        padding-left: 7.9%; */
        /* background-color: blueviolet; */
    /* } */

    .ModalAbout_about__ktVHN {
        border: solid var(--line) 1px;
        border-radius: 4px 4px 4px 4px;
        width: 150px;
    }

}
.ModalContact_contact__1fRWi {
    border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 25px 20px 25px;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    color: var(--text-primary);;
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer;
    min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
}

.ModalContact_contactForm__2cLzx {
    /* display: flex; */
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 15px;

}

.ModalContact_nameLabel__3VVjg {
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 1.5;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 0;
    /* color: green; */
}
.ModalContact_inputName__FEVZf {
    display: block;
    height: 2.75rem;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px #ffffff;
    color: inherit;
    outline: 0;
    text-decoration: none;
    width: 100%;
    font-weight: 300;
    font-size: 1rem;
}

.ModalContact_emailLabel__68bUf {
    color: #ffffff;
    display: block;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 1.5;
    text-transform: uppercase;
    margin-top: 2%;

    padding-left: 15px;
}
.ModalContact_inputEmail__1md_5 {
    display: block;
    
    height: 2.75rem;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px #ffffff;
    color: inherit;
    outline: 0;
    text-decoration: none;
    width: 100%;
}

.ModalContact_messageLabel__30tRw {
    color: #ffffff;
    display: block;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 1.5;
    /* margin: 0 0 1rem 0; */
    text-transform: uppercase;
    margin-top: 2%;
    padding-left: 15px;
    width: 500px;
}

.ModalContact_textAreaLabel__CZCtJ {
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px #ffffff;
    color: inherit;
    display: block;
    outline: 0;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.65;
    width: 100%;
    height: 150px;
    padding: 0.75rem 1rem;
    margin-bottom: 8px 0;
    box-sizing: border-box;
    text-decoration: none;
}

.ModalContact_submitBtn__3ILHa {
    display: inline-block;
    position: relative;
    justify-self: left;
    -webkit-appearance: none;
            appearance: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: #ffffff;
    color: #1b1f22 !important;
    font-weight: 600;
    border-radius: 4px;
    border: 0;
    box-shadow: inset 0 0 0 1px #ffffff;
    cursor: pointer;
    font-size: 0.8rem;
    height: 2.75rem;
    letter-spacing: 0.2rem;
    outline: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    margin-top: 2%;
    /* margin-left: 15px; */
}

.ModalContact_resetBtn__1_9qv {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.075);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
    font-size: 0.8rem;
    font-weight: 300;
    height: 2.75rem;
    letter-spacing: 0.2rem;
    outline: 0;
    padding: 0 1.25rem 0 1.35rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    border-radius: 4px;
    width: 100%;
    margin-top: 2%;
    /* margin-left: 15px; */
}

@media all and (max-width: 565px) {
    .ModalContact_modalBtnContainer__X750C {
        contain: style layout inline-size;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 13%;
        left: 700px;
    }
    
    .ModalContact_contact__1fRWi {
        border: solid var(--line) 1px;
        border-radius: 4px 4px 4px 4px;
        width: 150px;
    } 

    .ModalContact_contactForm__2cLzx {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;    
        padding: 0;
    }


    .ModalContact_nameLabel__3VVjg {
        grid-column: span 1;
        order: 1;
    }
            
    .ModalContact_emailLabel__68bUf {
        grid-column: span 1;
        order: 2;
    }
            
    .ModalContact_messageLabel__30tRw {
        grid-column: 1 / -1;
        order: 3;
    }
            
    .ModalContact_submitBtn__3ILHa {
        grid-column: span 1;
        order: 4;
    }
            
    .ModalContact_resetBtn__1_9qv {
        grid-column: span 1;
        order: 4;
    }

    .ModalContact_nameLabel__3VVjg {
        color: #ffffff;
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.2rem;
        line-height: 1.5;
        margin: 0 0 1rem 0;
        text-transform: uppercase;
        padding-left: 46px;
        padding-right: 0;
        width: 96%;
    }

    .ModalContact_nameLabel__3VVjg {
        margin-right: 0;
    }

    .ModalContact_emailLabel__68bUf {
        color: #ffffff;
        display: block;
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.2rem;
        line-height: 1.5;
        text-transform: uppercase;
        /* margin-left: 15px; */
        padding-left: 46px;
        width: 86%;
    }

    .ModalContact_messageLabel__30tRw {
        color: #ffffff;
        display: block;
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.2rem;
        line-height: 1.5;
        margin: 0 0 1rem 0;
        text-transform: uppercase;
        padding-left: 46px;
        width: 96%;
    }

    .ModalContact_textAreaLabel__CZCtJ {
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
        background-color: transparent;
        border-radius: 4px;
        border: solid 1px #ffffff;
        color: inherit;
        display: block;
        outline: 0;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.65;
        width: 80%;
        padding: 0.75rem 1rem;
        margin: 8px 0;
        box-sizing: border-box;
        text-decoration: none;
    }

    .ModalContact_submitBtn__3ILHa {
        margin-left: 46px;
        margin-bottom: 15px;
        width: 68%;
    }

    .ModalContact_resetBtn__1_9qv {
        margin-left: 46px;
        /* padding-left: 46px; */
        width: 68%;
        border: solid white 1px;
    }
}

/* #root {
    height: 100%;
} */

.LandingPageFooter_contactIcons__122Bn {
    display: flex;
    justify-content: space-evenly;
    /* flex-direction: row; */

    padding-top: 25px;
    padding-left: 0;
    font-family: 'Nunito',
    sans-serif;
    text-transform: uppercase;
    /* border: red solid 1px; */
    
}

/* .linkedIn {
    border: red solid 1px;
} */

/* .intro:hover, .intro:focus {
    background-color: var(--opaqe);
    border: red solid 1px;
} */

/* .work:hover, .work:focus {
    background-color: var(--opaqe);
} */

/* .contactIcons:hover, .about:focus {
    background-color: var(--opaqe);
} */

.LandingPageFooter_dreGit__owT57 {
    border: red solid 1px;
}

.LandingPageFooter_twitter__1AW6q:hover {
    

    -webkit-transform: rotateZ(720deg);
      transform: rotateZ(720deg);
      /* border: red solid 1px; */
   
}


/* .contactIcons {
    display: inline-block;
    width: 200px;
    border: 1px solid red;
    overflow: hidden;
    text-align: center;
} */

/* .linkedIn:hover{
    color: blue;

} */
/* * {
  width: 100%;
  height: 100%;
} */
.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
  /* background-color: #46045e; */
}

#dark .app {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-color: var(--background);
  padding: 3rem 2rem;
}

body {
  /* display: flex;
  flex-direction: column; */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100dvh;
  /* border: solid 5px red; */
}

/* body {
  flex-grow: 1;
} */

:root {
  --background: #ffffff;
  --text-primary: #000;
  --line: #000;
  /* --f */
  --opaqe: rgb(112, 108, 108, .3);
  /* border: solid 5px yellow */
}

[data-theme='dark'] {
  --background: #000;
  --text-primary: #ffffff;
  --line: #ffffff;
  /* --opaqe: rgb(112, 108, 108, .3); */
}



#light {                   /* <---------------light mode context  */
  background-color: #f3ebf6;
}

#dark {                    /* <---------------dark mode context  */
  background-color: #46045e;
}


*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  /* filter: blur(8px); */
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
  /* background-repeat: no-repeat; */
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* background-color: blueviolet; */
  /* border: solid 5px red; */
  /* transition: all .5s; */
}

.app-background {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* background: rgba(0, 0,0, 0.7); */
  align-items: center;
  justify-content: center;
  /* border: solid 5px blue; */

  /* background-image: url(./image/mountains1.jpg); */
  /* background-repeat: no-repeat; */
  /* width: 100%;
  height: 100%; */
  transition: all .5s;
}

.filter-shade {
  display: flex;
  height: 100%;
  width: 100%;
  /* background: rgba(171, 6, 6, 0.314); */
  background: green;
  align-items: center;
  justify-content: center;


}

.small-container {
  /* filter: blur(8px); */
 display: flex;
 flex-direction: column;
 padding: 2rem 1rem;
 width: 600px;  
}

ul {
  display: flex;
}

li {
  list-style: none;
  display: flex;
  flex-wrap: wrap; 
  grid-gap: 0px; 
  gap: 0px;
  /* border: solid green 5px; */
}

.list-work {
  display: flex;
  text-transform: uppercase;
  align-self: center;
  justify-content: space-evenly;
  font-family: 'Nunito',
  sans-serif;
  font-weight: 200px;
  font-size: 12px;  
}

ul.horizontal-list {
  list-style: none;
  padding-top: 0px;
}

ul.horizontal-list li {
  display: inline;   
}

@media all and (max-width: 600px) {
  #light {                   /* <---------------light mode context  */
    background-color: #f3ebf6;
  }
  
  #dark {                    /* <---------------dark mode context  */
    background-color: #46045e;
  }
/* app section  */
  .app {
    height: 100%;
    width: 100%;
    /* border: solid 5px pink; */
  }

  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100%;
    /* border: solid 5px green; */
  }

  .app-background {
    width: 100%; 
    height: 60%;
    /* border: solid 5px blueviolet;  */
  }

  .container {
    /* filter: blur(8px); */
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 100%;
    /* background-repeat: no-repeat; */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    /* background-color: blueviolet; */
    /* border: solid 5px green; */
    /* transition: all .5s; */
  }



  .switch {
    display: flex;
    top: 10px;
    height: 100px;
    width: 95%;
    justify-content: right;
    align-items: center;
    margin-right: 30px;
    background-color: #46045e00;
    /* border: solid red 5px; */
  }

    .small-container {
      /* filter: blur(8px); */
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      width: 100%;
    }

  
}

.switch {
  display: flex;
  top: 10px;
  height: 100px;
  width: 95%;
  justify-content: right;
  align-items: center;
  margin-right: 30px;
  background-color: #46045e00;
  /* border: solid red 5px; */
}


#dark .downlineBottom {
  border-left: solid #000  1px;
  border-left: solid var(--line) /* green*/ 1px;
}

#dark :root {
  --background: black;
  --line: black;
  --opaqe: rgb(112, 108, 108, .3);
  
}

