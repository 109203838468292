@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap');

.contact {
    border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 25px 20px 25px;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    color: var(--text-primary);;
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer;
    min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
}

.contactForm {
    /* display: flex; */
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 15px;

}

.nameLabel {
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 1.5;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 0;
    /* color: green; */
}
.inputName {
    display: block;
    height: 2.75rem;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px #ffffff;
    color: inherit;
    outline: 0;
    text-decoration: none;
    width: 100%;
    font-weight: 300;
    font-size: 1rem;
}

.emailLabel {
    color: #ffffff;
    display: block;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 1.5;
    text-transform: uppercase;
    margin-top: 2%;

    padding-left: 15px;
}
.inputEmail {
    display: block;
    
    height: 2.75rem;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px #ffffff;
    color: inherit;
    outline: 0;
    text-decoration: none;
    width: 100%;
}

.messageLabel {
    color: #ffffff;
    display: block;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 1.5;
    /* margin: 0 0 1rem 0; */
    text-transform: uppercase;
    margin-top: 2%;
    padding-left: 15px;
    width: 500px;
}

.textAreaLabel {
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px #ffffff;
    color: inherit;
    display: block;
    outline: 0;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.65;
    width: 100%;
    height: 150px;
    padding: 0.75rem 1rem;
    margin-bottom: 8px 0;
    box-sizing: border-box;
    text-decoration: none;
}

.submitBtn {
    display: inline-block;
    position: relative;
    justify-self: left;
    appearance: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: #ffffff;
    color: #1b1f22 !important;
    font-weight: 600;
    border-radius: 4px;
    border: 0;
    box-shadow: inset 0 0 0 1px #ffffff;
    cursor: pointer;
    font-size: 0.8rem;
    height: 2.75rem;
    letter-spacing: 0.2rem;
    outline: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    margin-top: 2%;
    /* margin-left: 15px; */
}

.resetBtn {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.075);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
    font-size: 0.8rem;
    font-weight: 300;
    height: 2.75rem;
    letter-spacing: 0.2rem;
    outline: 0;
    padding: 0 1.25rem 0 1.35rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    border-radius: 4px;
    width: 100%;
    margin-top: 2%;
    /* margin-left: 15px; */
}

@media all and (max-width: 565px) {
    .modalBtnContainer {
        contain: style layout inline-size;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 13%;
        left: 700px;
    }
    
    .contact {
        border: solid var(--line) 1px;
        border-radius: 4px 4px 4px 4px;
        width: 150px;
    } 

    .contactForm {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;    
        padding: 0;
    }


    .nameLabel {
        grid-column: span 1;
        order: 1;
    }
            
    .emailLabel {
        grid-column: span 1;
        order: 2;
    }
            
    .messageLabel {
        grid-column: 1 / -1;
        order: 3;
    }
            
    .submitBtn {
        grid-column: span 1;
        order: 4;
    }
            
    .resetBtn {
        grid-column: span 1;
        order: 4;
    }

    .nameLabel {
        color: #ffffff;
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.2rem;
        line-height: 1.5;
        margin: 0 0 1rem 0;
        text-transform: uppercase;
        padding-left: 46px;
        padding-right: 0;
        width: 96%;
    }

    .nameLabel {
        margin-right: 0;
    }

    .emailLabel {
        color: #ffffff;
        display: block;
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.2rem;
        line-height: 1.5;
        text-transform: uppercase;
        /* margin-left: 15px; */
        padding-left: 46px;
        width: 86%;
    }

    .messageLabel {
        color: #ffffff;
        display: block;
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.2rem;
        line-height: 1.5;
        margin: 0 0 1rem 0;
        text-transform: uppercase;
        padding-left: 46px;
        width: 96%;
    }

    .textAreaLabel {
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
        background-color: transparent;
        border-radius: 4px;
        border: solid 1px #ffffff;
        color: inherit;
        display: block;
        outline: 0;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.65;
        width: 80%;
        padding: 0.75rem 1rem;
        margin: 8px 0;
        box-sizing: border-box;
        text-decoration: none;
    }

    .submitBtn {
        margin-left: 46px;
        margin-bottom: 15px;
        width: 68%;
    }

    .resetBtn {
        margin-left: 46px;
        /* padding-left: 46px; */
        width: 68%;
        border: solid white 1px;
    }
}
