@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap');



/* .intro { */
    /* border: solid var(--line) 1px;
    padding: 20px 25px 20px 25px;
    border-radius: 4px 0 0 4px;
    text-decoration: none;
    color: var(--text-primary); */
    /* background-color: rgba(220, 20, 60, 0); */
    /* cursor: pointer; */


    /* display: block; */
    /* min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem; */
    /* border-bottom: 0; */
/* } */

/* .intro:hover, .intro:focus {
    background-color: var(--opaqe);
  } */

.work {
    border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 39px 20px 38px;
    text-decoration: none;
    color: var(--text-primary);
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer;

    /* display: block; */
    min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    /* border-bottom: 0; */
}

.work:hover, .work:focus {
    background-color: var(--opaqe);
  }

/* .about { */
    /* border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 27px 20px 20px;
    text-decoration: none;
    color: var(--text-primary);
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer; */

    /* display: block; */
    /* min-width: 7.5rem;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 1.25rem 0 1.45rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem; */
    /* border-bottom: 0; */
/* } */

/* .about:hover, .about:focus {
    background-color: var(--opaqe); */
    /* background-color: blue; */
  /* } */

.contact {
    border-top: solid var(--line) 1px;
    border-right: solid var(--line) 1px;
    border-bottom: solid var(--line) 1px;
    padding: 20px 25px 20px 25px;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    color: var(--text-primary);;
    background-color: rgba(220, 20, 60, 0);
    cursor: pointer;
    /* background-color: blue; */
}

.contact:hover, .contact:focus {
    background-color: var(--opaqe);
    /* background-color: blue; */
  }

.modalBtnContainer {
  padding-left: 45px;
  /* background-color: blue; */
    padding-left: 7.9%;
}

@media all and (max-width: 565px) {
    .modalBtnContainer {
        contain: style layout inline-size;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 13%;
        left: 700px;
        padding-left: 7.9%;
        /* background-color: blueviolet; */
    }

        /* .intro{
            border: solid var(--line) 1px;
            border-radius: 4px 4px 4px 4px;
            width: 150px;

        } */

        .work {
            border: solid var(--line) 1px;
              border-radius: 4px 4px 4px 4px;
              width: 150px;
          }
          /* .about {
            border: solid var(--line) 1px;
              border-radius: 4px 4px 4px 4px;
              width: 150px;
          } */
          .contact {
            border: solid var(--line) 1px;
              border-radius: 4px 4px 4px 4px;
              width: 150px;
              /* background-color: tomato; */
          } 

}
