@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Istok+Web:wght@400;700&family=Lato:wght@100;300;400;700&family=Righteous&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap');

/* * {
  width: 100%;
  height: 100%;
} */
.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
  /* background-color: #46045e; */
}

#dark .app {
  width: 100%;
  height: 100%;
  background-color: var(--background);
  padding: 3rem 2rem;
}

body {
  /* display: flex;
  flex-direction: column; */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100dvh;
  /* border: solid 5px red; */
}

/* body {
  flex-grow: 1;
} */

:root {
  --background: #ffffff;
  --text-primary: #000;
  --line: #000;
  /* --f */
  --opaqe: rgb(112, 108, 108, .3);
  /* border: solid 5px yellow */
}

[data-theme='dark'] {
  --background: #000;
  --text-primary: #ffffff;
  --line: #ffffff;
  /* --opaqe: rgb(112, 108, 108, .3); */
}



#light {                   /* <---------------light mode context  */
  background-color: #f3ebf6;
}

#dark {                    /* <---------------dark mode context  */
  background-color: #46045e;
}


*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  /* filter: blur(8px); */
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
  /* background-repeat: no-repeat; */
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* background-color: blueviolet; */
  /* border: solid 5px red; */
  /* transition: all .5s; */
}

.app-background {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* background: rgba(0, 0,0, 0.7); */
  align-items: center;
  justify-content: center;
  /* border: solid 5px blue; */

  /* background-image: url(./image/mountains1.jpg); */
  /* background-repeat: no-repeat; */
  /* width: 100%;
  height: 100%; */
  transition: all .5s;
}

.filter-shade {
  display: flex;
  height: 100%;
  width: 100%;
  /* background: rgba(171, 6, 6, 0.314); */
  background: green;
  align-items: center;
  justify-content: center;


}

.small-container {
  /* filter: blur(8px); */
 display: flex;
 flex-direction: column;
 padding: 2rem 1rem;
 width: 600px;  
}

ul {
  display: flex;
}

li {
  list-style: none;
  display: flex;
  flex-wrap: wrap; 
  gap: 0px;
  /* border: solid green 5px; */
}

.list-work {
  display: flex;
  text-transform: uppercase;
  align-self: center;
  justify-content: space-evenly;
  font-family: 'Nunito',
  sans-serif;
  font-weight: 200px;
  font-size: 12px;  
}

ul.horizontal-list {
  list-style: none;
  padding-top: 0px;
}

ul.horizontal-list li {
  display: inline;   
}

@media all and (max-width: 600px) {
  #light {                   /* <---------------light mode context  */
    background-color: #f3ebf6;
  }
  
  #dark {                    /* <---------------dark mode context  */
    background-color: #46045e;
  }
/* app section  */
  .app {
    height: 100%;
    width: 100%;
    /* border: solid 5px pink; */
  }

  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100%;
    /* border: solid 5px green; */
  }

  .app-background {
    width: 100%; 
    height: 60%;
    /* border: solid 5px blueviolet;  */
  }

  .container {
    /* filter: blur(8px); */
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 100%;
    /* background-repeat: no-repeat; */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    /* background-color: blueviolet; */
    /* border: solid 5px green; */
    /* transition: all .5s; */
  }



  .switch {
    display: flex;
    top: 10px;
    height: 100px;
    width: 95%;
    justify-content: right;
    align-items: center;
    margin-right: 30px;
    background-color: #46045e00;
    /* border: solid red 5px; */
  }

    .small-container {
      /* filter: blur(8px); */
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      width: 100%;
    }

  
}

.switch {
  display: flex;
  top: 10px;
  height: 100px;
  width: 95%;
  justify-content: right;
  align-items: center;
  margin-right: 30px;
  background-color: #46045e00;
  /* border: solid red 5px; */
}


#dark .downlineBottom {
  border-left: solid var(--line) /* green*/ 1px;
}

#dark :root {
  --background: black;
  --line: black;
  --opaqe: rgb(112, 108, 108, .3);
  
}
